const data = [
  {
    class: "XII",
    name: "ADITI JOSHI",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/ADITI-JOSHI-CLASS-XII-(2).jpg",
  },
  {
    class: "XII",
    name: "ADITI JOSHI",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/ADITI-JOSHI-CLASS-XII.jpg",
  },
  {
    class: "IX",
    name: "ANANYA SAINI",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/ANANYA-SAINI-CLASS-IX.jpg",
  },
  {
    class: "XII",
    name: "HARSHITA",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/HARSHITA-CLASS-XII.jpg",
  },
  {
    class: "XII",
    name: "HIMANSHI",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/HIMANSHI-CLASS-XII.jpg",
  },
  {
    class: "VIII",
    name: "KAUSHAL BHARDWAJ",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/KAUSHAL-BHARDWAJ-CLASS-VIII.jpg",
  },
  {
    class: "XII",
    name: "KESHAV",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/KESHAV-CLASS-XII.jpg",
  },
  {
    class: "XII",
    name: "MADHURITA BANERJEE",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/MADHURITA-BANERJEE-XII.jpg",
  },
  {
    class: "XII",
    name: "NITYA SHREERAM",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/NITYA-SHREERAM-CLASS-XII.jpg",
  },
  {
    class: "IX",
    name: "SHYAMASHREE",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/SHYAMASHREE-CLASS-IX.jpg",
  },
  {
    class: "VII",
    name: "SOHAM CHANDOLA",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/SOHAM-CHANDOLA-CLASS-VII.jpg",
  },
  {
    class: "XII",
    name: "VANDITA BHARDWAJ",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/VANDITA-BHARDWAJ-CLASS-XII.jpg",
  },
  {
    class: "VIII",
    name: "YASHICA PURI",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/YASHICA-PURI-CLASS-VIII.jpg",
  },
  {
    class: "XI",
    name: "ZYAN KHAN",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/ZYAN-KHAN-CLASS-XI.jpg",
  },
];

export default data;
